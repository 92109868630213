<template>
  <a-config-provider :locale="locale">
    <a-spin :spinning="spinning" wrapperClassName="spinwrapperClassName" size="large">
      <router-view />
    </a-spin>
  </a-config-provider>
</template>
<script setup>
import { provide, ref } from 'vue'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import router, { RouterMap } from "./router/config.js"
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { getCurrentInstance } from 'vue'
const instance = getCurrentInstance()
const _this = instance.appContext.config.globalProperties
dayjs.locale('zh-cn');
const locale = zhCN

router.beforeEach((to, from, next) => {
  NProgress.start();
  if (to.path === '/login') {
    if (_this.ls.get("ACCESSTOKEN")) {
      return next('/');
    }
  } else {
    if (!_this.ls.get("ACCESSTOKEN")) {
      return next('/login');
    }
  }
  next();
});

_this.ls.set("ACCESSTOKEN", 123)
let spinning = ref(true)
let selectedKeys_sidemenu = ref([])
let openKeys_sidemenu = ref([])
let selectedKeys_topmenu = ref([])
router.afterEach((to, from, failure) => {
  NProgress.done();
  spinning.value = false
  if (!failure) {
    //selectedKeys_sidemenu.value=
    selectedKeys_sidemenu.value = [to.matched[to.matched.length - 1].name]
    openKeys_sidemenu.value = []
    to.matched.forEach((v, i) => {
      openKeys_sidemenu.value.push(v.name)
    })
  }
})
provide('selectedKeys_sidemenu', selectedKeys_sidemenu);
provide('openKeys_sidemenu', openKeys_sidemenu);
provide('selectedKeys_topmenu', selectedKeys_topmenu);

</script>
<style>
#app {
  height: 100%
}

.spinwrapperClassName {
  height: 100%
}

.ant-spin-container {
  height: 100%
}
</style>