import { createRouter, createWebHashHistory } from "vue-router"
import { DashboardOutlined, SnippetsOutlined, CloudServerOutlined, FormOutlined, WindowsOutlined } from "@ant-design/icons-vue"
export const RouterMap = [
  {
    path: "/",
    name: "index",
    meta: {
      title: "首页"
    },
    component: () => import("@/views/index/Index"),
    //redirect: "/dashboard/workplace",
    children: [
      {
        path: "/preview",
        name: "preview",
        meta: {
          title: "页面预览", icon: WindowsOutlined,
        },
        redirect: "/preview/table",
        children: [
          {
            path: "/preview/table",
            name: "previewTable",
            meta: { title: "企业信息管理" },
            component: () => import("@/views/preview/Table")
          },
          {
            path: "/preview/form",
            name: "previewForm",
            meta: { title: "调研表单示例" },
            component: () => import("@/views/preview/Form")
          },
        ]
      },

    ]
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "登录"
    },
    component: () => import("@/views/user/LoginPage")
  }
]
const routerHistory = createWebHashHistory()
const router = createRouter({
  history: routerHistory,
  routes: RouterMap
})

export default router
