import { createApp } from 'vue';
import Antd from 'ant-design-vue';
import App from './App';
import 'ant-design-vue/dist/reset.css';
import router from "./router/config.js"
import VueStoragePlugin from "vue-ls"
const app = createApp(App);
app.config.globalProperties.ls = VueStoragePlugin.useStorage({
    namespace: "pro__",
    name: "ls",
    storage: "local"
}).ls

app.use(router)
app.use(Antd)
app.mount('#app')
